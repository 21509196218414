/* Level selector */

  
.level-selector-nav {
    width: 800px;
    margin-left: auto;
    margin-right: auto;
}

.powered-by {
    margin-left: auto;
    margin-top: -2em;
    margin-bottom: -2em;
    margin-right: auto;
    text-align: center;
}


.dropdown-menu-bar {
    width: inherit;
    display: flex;
    justify-content: space-between;
    align-items:center;
    padding: 5px 30px;
    margin-top: 1%;
    overflow: hidden;
    border-radius: 6px;
    font-style: none;
    font-family: "Exo-Space";
    font-weight: bolder;
    font-size: larger;
    text-decoration: none;
    cursor: pointer;
    font-size: 20px;
}

.button-sequence {
    margin-bottom: 2%;
    text-align: center;
    justify-content: center;
    display: flex;
}

.level-title {
    margin-left: 43%;
}

.dropdown-menu-bar p{
    height: 10px;
}

.dropdown-menu-bar-button{
    overflow: hidden;
}

.level-selector-nav a{
    font-style: none;
    color: black;
    font-family: "Exo-Space";
    font-weight: bolder;
    text-decoration: none;
}

.level-selector-dropdown-content{
    display: none;
    margin-top: 1%;
    overflow: hidden;
    border: solid white 2px;
    border-radius: 8px;
    font-style: none;
    color: white;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: normal;
    text-decoration: none;
    overflow-y: auto; 
    height: auto;
}

.level-selector-dropdown-content-item {
    display: flex;
    justify-content: space-between;
    text-align:center;
    width: auto;
    color: white;
}

.level-selector-dropdown-content-item p {
    margin-top: 15px;
    margin-bottom: 10px;
}

.level-selector-dropdown-content-item a{
    background-color: white;
    color: black;
    z-index: 1;
}

.dropdown-button{
    color: white;
    background-color: #111111;
}

.level-selector-dropdown-content-item:hover {
    background-color: black;
    color: white;
    border: solid black 2px;
    border-radius: 6px;
}

.level-selector-dropdown-content-item:hover a{
    background-color: black;
    color: white;
}

/* .level-selector-nav:hover .level-selector-dropdown-content{
    display: block;
    z-index: 1;
    background-color: var(--primary-color);
} */

.level-img-view {
    border-radius: 25px;
    position: relative;
    width: 800px;
    display: block
}

@media only screen and (max-width: 885px) {
    .button-sequence {
        margin-bottom: 30px;
        text-align: center;
        justify-content: center;
        display: flex;
    }
    .level-selector-nav {
        width: 100%;
    }
    
    .level-selector-dropdown-content {
        height: auto;
    }
}
