.landing-container{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 100vh; /* fallback for Js load */
    height: var(--doc-height);
    width: 100vw;
    min-height: -webkit-fill-available;
    min-width: -webkit-fill-available;
    overflow: hidden;
    padding-left: 3rem;
}

/* title wrapper*/
.title-wrapper {
    display: flex;
    align-items: center;
    margin-top: 2rem;
    height: 6%;
}

.oz-logo {
 height: 95%;
}

.title-separator {
    height: 100%;
    background-color: #fff;
    width: 0.2rem;
    margin-left: 1.5rem;
    margin-right: 1rem;
}

.title-text {
    margin-top: 1em;
    height: 35.5%;
    width: 35em;
    max-width: 80%;
    justify-content: center;
    display: flex;
}

.title-text-landing {
    height: 35.5%;
    width: 22em;
    max-width: 80%;
    justify-content: center;
    display: flex;
}

/*Main container*/
.main-container{
    height: auto;
    display: flex;
    flex-direction: row;
}

/*Main left container*/
.left-container {
    height: 100%;
    width: 45%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.subtitle-text {
    position: relative;
    /* font-family: "Exo-Space"; */
    font-size: 3rem;
    overflow-wrap: normal;
    color: #fff;
}

.description-text{
    position: relative;
    font-size: 2em;
    max-width: 80%;
    overflow-wrap: normal;
    color: #fff;
    margin-top: 2rem;
}

/* the button will be abstracted app.css*/

.submission-button-wrap {
    margin-top: 2rem;
    height: 50px;
    width: auto;
}

.sepolia-button-wrap {
    height: 50px;
    width: auto;
}
 
.submission-button:hover {
    opacity: 0.85;
}

.submission-button-text {
    font-size: 1.2em;
    margin: 0 15px;

}
.submission-button-text:hover{
    background-color: #cecece00
}


/*Main right container*/
.right-container {
    position: relative;
    height: 100%;
    width: 55%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.purple-halo {
    position: absolute;
    opacity: 0.45;
    width: 120%;
    height: 35%;
    z-index: 0;
    background-image: radial-gradient(#4e2875 0%,#362bcc 30%, #6a1170 100%);
    border-start-start-radius: 30% 50% ;
    border-start-end-radius: 80% 50% ;
    border-end-start-radius: 80% 50% ;
    border-end-end-radius: 30% 50% ;
    transform: translate(0%, 50%) rotate(-17deg) ;
    filter: blur(3rem);
}

.yellow-halo {
    position: absolute;
    opacity: 0.85;
    width: 23%;
    height: 39%;
    z-index: 0;
    background-image: radial-gradient(#eb370e 0%,#cd4728 30%, #ed9c3b 100%);
    border-radius: 50%;
    transform: translate(50%, 60%) rotate(60deg) ;
    filter: blur(3rem);
}

.landing-image {
    position: relative;
    object-fit: fill;
    height: auto;
    width: 85%;
}

/*Form container*/
.form-container-wrap{
    width: 65%;
    margin: 1.5rem 0rem;
    position: relative;
}

.form-container{
    /* width: 90vw; */
    flex-direction: column;
    padding: 2rem;
    text-align: left;
}

.close-button {
    position: absolute;
    right: 1rem;
    top: .8rem;
}

.close-button:hover {
    cursor: default;
}

.poaps-ul {
    margin-top: 1rem;
    margin-left: 2rem;
}

.hubspot-form{
    width: 35%;
}
/*Tablet*/
@media (max-width: 1023px) {

    .landing-container{
        padding: 1.5rem;
        align-items: center;
        justify-content: start;
    }

    .title-text-landing {
        margin-top: 1em;
    }

    /*Main container*/
    .main-container{
        flex-direction: column;
        align-items: center;
        padding-left: 0;
    }

    /* title wrapper*/
    .title-wrapper {
        margin-top: 0rem;
        flex-direction: column;
        height: auto;
        width: 80%;
    }

    .oz-logo {
        width: 100%;
    }
       

    .title-separator {
        width: 100%;
        height: 0.1rem;
        margin-top: 0.5rem;
        margin-left: 0rem;
        margin-right: 0rem;
    }
    

    /*Main left container*/
    .left-container {
        width: 100%;
        align-items: center;
    }

    .subtitle-text{
        margin-top: 2rem;
        font-size: 2rem;
        text-align: center;
        overflow-wrap: break-word;
    }

    .description-text {
        font-size: 1.2rem;
        width: 95%;
        text-align: center;
        overflow-wrap: break-word;
        margin-top: 1.5rem;
    }

    .submission-button-wrap {
        margin-left: 0px;
        margin-top: 1.5rem;
    }

    /*Main right container*/
    .right-container {
        width: 100%;
        justify-content: center;
    }
}

/* Mobile */
@media (max-width: 767px) {

    
    .landing-container{
        padding: 1.5rem;
    }

    /* title wrapper*/
    .title-wrapper {
        width: 80%;
    }

    /*Main left container*/
    .subtitle-text {
        margin-top: 1.2rem;
    }

    .description-text {
        font-size: 1rem;
        margin-top: 0.6rem;
        max-width: 100%;
    }

    .submission-button-wrap {
        margin-top: 1.3rem;
    }
    
}




@media (max-width: 400px) {
    .submission-button-text {
        font-size: 1em;
    }
}



/* .palette{
    
    color: #1593a0;
    color: #93ece7;
    color: #0b647a;
    
    color: #58a385;
    color: #86b384;
    color: #83cd9a;

    color: #6f2877;
    color: #4e2875;
    color: #6a1170;
    color: #45114b;

    color: #ed9c3b;
    color: #f7d769;
    color: #cd4728;
    
    color: #7e263f;
    color: #551717;
    color: #d88c8d;
} */